// @flow strict
import React from "react";
import { getTweetingUrl } from "../../../utils";
import styles from "./Author.module.scss";
import { useSiteMetadata } from "../../../hooks";

type Props = {
  postSlug: string
};

const Author = ({ postSlug }: Props) => {
  const { author, url } = useSiteMetadata();
  return (
    <div className={styles["author"]}>
      <p className={styles["author__bio"]}>
        Thanks for reading! If you gained something from reading this article,
        feel free to{" "}
        <a
          href={getTweetingUrl(author.contacts.twitter, url, postSlug)}
          target="_blank"
          rel="noopener noreferrer"
        >
          tweet about it
        </a>{" "}
        or share your thoughts in the comments section below 💬.
      </p>
    </div>
  );
};

export default Author;
